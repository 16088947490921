import { communityUserRoles } from './loginConstants';

export const checkIfManager = (roles) => {
  const userRolesData =
    roles && !Array.isArray(roles) ? Object.keys(roles) : roles || [];
  const managerRoles = [
    communityUserRoles.MANAGER,
    communityUserRoles.OWNER,
    communityUserRoles.ADMIN
  ];
  const isManager = userRolesData.some((role) =>
    managerRoles.includes(role)
  );

  return isManager;
};

export const isMemberOnly = (roles) => {
  const userRolesData =
    roles && !Array.isArray(roles) ? Object.keys(roles) : roles || [];
  const isMemberOnly =
    userRolesData.length === 1 &&
    userRolesData.includes(communityUserRoles.MEMBER);

  return isMemberOnly;
};
