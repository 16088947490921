import { t } from '@/utility/localization';
import FloatingLabelInput from '../FloatingLabelInput';

const PasswordCreationField = ({
  onChange,
  name = 'password',
  label = t('password'),
  placeholder = t('password'),
  error,
  focus = false
}) => {
  return (
    <div className="space-y-24">
      <FloatingLabelInput
        type="password"
        name={name}
        placeholder={placeholder}
        label={label}
        floatingLabel={label}
        focus={focus}
        onChange={onChange}
        error={error}
      />

      <div className="rounded-8 bg-npl-neutral-light-solid-2 p-16">
        <p className="text-body-sm text-npl-text-icon-on-light-surface-primary">
          {t('your-password-must-contain')}
        </p>
        <ul className="list-disc pl-20">
          <li className="text-body-sm text-npl-text-icon-on-light-surface-primary">
            {t('characters')}
          </li>
          <li className="text-body-sm text-npl-text-icon-on-light-surface-primary">
            {t('uppercase-character')}
          </li>
          <li className="text-body-sm text-npl-text-icon-on-light-surface-primary">
            {t('lowercase-character')}
          </li>
          <li className="text-body-sm text-npl-text-icon-on-light-surface-primary">
            {t('number1')}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PasswordCreationField;
