import { getPrimaryMetaDataProps } from '@/features/CmOnboarding/constants';

import PageMetaTags from './PageMetaTags';

const PortalPagesMetaTags = () => {
  const metaData = {
    ...getPrimaryMetaDataProps(),
    url: process.env.NEXT_PUBLIC_SITE_BASE_URL
  };

  return <PageMetaTags {...metaData} />;
};

export default PortalPagesMetaTags;
