import {
  getTrackingInformationForBE,
  trackGAEvent
} from '@/utility/analytics';
import {
  NASIO_OG_META_IMAGE_SRC,
  STATIC_ASSET_BASE_URL
} from '@/utility/constants';
import { t } from '@/utility/localization';

import // SAMPLE_AVATAR_IMAGES,
'@/features/CmOnboarding/constants';

export const ONBOARDING_STEP_SET_COMMUNITY_NAME =
  'ONBOARDING_STEP_SET_COMMUNITY_NAME';
export const ONBOARDING_STEP_USER_PROFILE_LOGIN =
  'ONBOARDING_STEP_USER_PROFILE_LOGIN';
export const ONBOARDING_STEP_USER_PROFILE_CREATION =
  'ONBOARDING_STEP_USER_PROFILE_CREATION';
export const ONBOARDING_STEP_CONNECT_WHATSAPP =
  'ONBOARDING_STEP_CONNECT_WHATSAPP';
export const ONBOARDING_STEP_COPY_LINK_PAGE =
  'ONBOARDING_STEP_COPY_LINK_PAGE';
export const GO_TO_CM_PORTAL = 'GO_TO_CM_PORTAL';

export const COMMUNITY_PAYMENT_TYPE = {
  FREE: 'free',
  PAID: 'paid'
};

export const PROCESSING_FEE_PAID_BY = {
  ME: 'me',
  MY_MEMBERS: 'my-members'
};

// TODO - @Aman - Maybe can remove
export const AVATAR_IMAGES = [
  {
    alt: 'Sample Person 1',
    mobileImgProps: {
      src: `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/png/sample_avatar_1.png`,
      width: 40,
      height: 40
    }
  },
  {
    alt: 'Sample Person 2',
    mobileImgProps: {
      src: `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/png/sample_avatar_2.png`,
      width: 40,
      height: 40
    }
  },
  {
    alt: 'Sample Person 3',
    mobileImgProps: {
      src: `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/png/sample_avatar_3.png`,
      width: 40,
      height: 40
    }
  },
  {
    alt: 'Sample Person 4',
    mobileImgProps: {
      src: `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/png/sample_avatar_4.png`,
      width: 40,
      height: 40
    }
  }
];

// Static Labels
export const EMAIL_PLACEHOLDER = 'john.doe@nas.io';

// Analytics
export const CREATE_COMMUNITY_WITH_NAME_EVENT = 'create_community_named';
export const START_COMMUNITY_ONBOARDING = 'create_community_start';
export const START_WA_COMMUNITY_ONBOARDING =
  'create_whatsapp_community_start';
export const COMMUNITY_ONBOARDING_COMPLETE =
  'create_commuinty_navigate_cmp';
export const WA_COMMUNITY_ONBOARDING_COMPLETE =
  'create_whatsapp_commuinty_navigate_cmp';

export const trackStartCommunityOnboarding = () => {
  const trafficSource = getTrackingInformationForBE();
  const payload = {
    trafficSource: trafficSource,
    ...trafficSource
  };
  trackGAEvent(START_COMMUNITY_ONBOARDING, payload);
};

export const trackOnboardingCompleted = (payload = {}) => {
  trackGAEvent(COMMUNITY_ONBOARDING_COMPLETE, payload);
};

// Inboarding flow storage keys
// resume onboarding flow storage key
export const RESUME_ONBOARDING_FLOW_KEY = 'RESUME_ONBOARDING_FLOW_KEY';
export const ONBOARDING_COMMUNITY_DATA_KEY =
  'WA_ONBOARDING_COMMUNITY_DATA_KEY';
export const ONBOARDING_ACTIVE_STEP_KEY = 'WA_ONBOARDING_ACTIVE_STEP_KEY';
export const WA_ONBOARDING_COMMUNITY_DATA_KEY =
  'WA_ONBOARDING_COMMUNITY_DATA_KEY';
export const WA_ONBOARDING_ACTIVE_STEP_KEY =
  'WA_ONBOARDING_ACTIVE_STEP_KEY';

export const approveMakeAdminImg = `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/png/approve-make-admin.png`;
export const makeAdminGroupImg = `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/png/make-admin-of-group.png`;
export const addNumbermakeAdminImg = `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/jpg/add-phone-make-admin-new.jpg`;

// ------------------------
export const CHOOSE_NFT_TITLE = 'Choose which NFT to allow access';
export const CHOOSE_NFT_SUBTITLE =
  'We only support Ethereum chain (ERC-721) at the moment. ETH ERC-1155 and Solana are Coming Soon!';

export const HOSTED_BY_SUBTITLE = (hostName) =>
  `${t('hosted-by')} ${hostName}`;

export const MEMBERS = 'Members';

export const NAS_EMAIL_SENT_IMAGE = {
  alt: 'Email Check',
  mobileImgProps: {
    src: `${STATIC_ASSET_BASE_URL}/nasIO/new_home/png/check_email_banner.png`,
    width: 300,
    height: 200
  }
};

export const NASIO_LOGO_IMAGE = {
  alt: ' Logo',
  mobileImgProps: {
    src: `${STATIC_ASSET_BASE_URL}/nasIO/new_home/svg/nasio_logo_white.svg`,
    width: 110,
    height: 24
  }
};

export const getPrimaryMetaDataProps = () => ({
  title: t(
    'nas-io-grow-engage-monetize-turn-your-community-into-a-business'
  ),
  description: t(
    'the-most-creator-friendly-platform-we-built-it-it-cost-0-to-use-nas-io-and-with-take-rates-as-low-as-0-manage-and-build-your-community-with-nas-io-today'
  ),
  imageUrl: NASIO_OG_META_IMAGE_SRC
});

export const getStartCommunityMetaDataProps = () => ({
  title: t('start-your-community-for-free-and-make-money-today'),
  description: t(
    'monetise-your-whatsapp-group-telegram-group-or-your-discord-server-with-exclusive-memberships-courses-events-and-more'
  ),
  imageUrl: `${STATIC_ASSET_BASE_URL}/nasIO/new_home/jpg/homepage-meta-image-2023.jpg`
});

export const SAMPLE_PEOPLE_IMAGES = [
  {
    alt: 'Sample Person 1',
    mobileImgProps: {
      src: `${STATIC_ASSET_BASE_URL}/nasIO/common/png/sample_person_1.png`,
      width: 80,
      height: 80
    }
  },
  {
    alt: 'Sample Person 2',
    mobileImgProps: {
      src: `${STATIC_ASSET_BASE_URL}/nasIO/common/png/sample_person_2.png`,
      width: 80,
      height: 80
    }
  },
  {
    alt: 'Sample Person 3',
    mobileImgProps: {
      src: `${STATIC_ASSET_BASE_URL}/nasIO/common/png/sample_person_3.png`,
      width: 80,
      height: 80
    }
  },
  {
    alt: 'Sample Person 4',
    mobileImgProps: {
      src: `${STATIC_ASSET_BASE_URL}/nasIO/common/png/sample_person_4.png`,
      width: 80,
      height: 80
    }
  },
  {
    alt: 'Sample Person 5',
    mobileImgProps: {
      src: `${STATIC_ASSET_BASE_URL}/nasIO/common/png/sample_person_5.png`,
      width: 80,
      height: 80
    }
  }
];

export const NAS_IO_LOGO = {
  alt: 'Nas IO',
  mobileImgProps: {
    src: `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/svg/logo.svg`,
    width: 72,
    height: 72
  }
};

export const getPricingOptions = () => [
  {
    id: 'paid',
    label: t('paid'),
    description: t('charge-a-fee-for-access'),
    iconProps: {
      name: 'currency-dollar-circle',
      fill: '#D2A500'
    }
  },
  {
    id: 'free',
    label: t('free'),
    description: t('anyone-can-join-for-free'),
    iconProps: {
      name: 'log-in',
      fill: '#18AA79'
    }
  }
];

export const DEFAULT_COMMUNITY_IMAGE_1 = `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/jpg/default-community-logo-blue.jpg`;
export const DEFAULT_COMMUNITY_IMAGE_2 = `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/jpg/default-community-logo-green.jpg`;
export const DEFAULT_COMMUNITY_IMAGE_3 = `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/jpg/default-community-logo-orange.jpg`;
export const DEFAULT_COMMUNITY_IMAGE_4 = `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/jpg/default-community-logo-pink.jpg`;
export const DEFAULT_COMMUNITY_IMAGE_5 = `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/jpg/default-community-logo-yellow.jpg`;

export const RANDOM_COMMUNITY_IMAGE = () => {
  const randomNumber = Math.floor(Math.random() * 5) + 1;
  switch (randomNumber) {
    case 1:
      return DEFAULT_COMMUNITY_IMAGE_1;
    case 2:
      return DEFAULT_COMMUNITY_IMAGE_2;
    case 3:
      return DEFAULT_COMMUNITY_IMAGE_3;
    case 4:
      return DEFAULT_COMMUNITY_IMAGE_4;
    case 5:
      return DEFAULT_COMMUNITY_IMAGE_5;
    default:
      return DEFAULT_COMMUNITY_IMAGE_1;
  }
};

export const DEFAULT_COMMUNITY_BANNER_IMAGE = `${STATIC_ASSET_BASE_URL}/nasIO/onboarding/png/default-background-1.png`;

export const getDefaultMonthlyPriceByCurrency = (currency) => {
  switch (currency) {
    case 'USD':
      return 5;
    case 'INR':
      return 99;
    default:
      return 100;
  }
};

export const SAMPLE_AVATAR_IMAGES = [
  {
    alt: 'Sample Person 1',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/png/sample_avatar_1.png',
      width: 40,
      height: 40
    }
  },
  {
    alt: 'Sample Person 2',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/png/sample_avatar_2.png',
      width: 40,
      height: 40
    }
  },
  {
    alt: 'Sample Person 3',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/png/sample_avatar_3.png',
      width: 40,
      height: 40
    }
  },
  {
    alt: 'Sample Person 4',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/png/sample_avatar_4.png',
      width: 40,
      height: 40
    }
  }
];

export const WHATSAPP_ICON = {
  alt: 'whatsapp',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/whatsapp_colored.svg',
    width: 48,
    height: 48
  }
};
export const CHAT_PLATFORMS = [
  {
    name: 'whatsapp',
    iconName: 'whatsapp-coloured'
  },
  {
    name: 'facebook',
    iconName: 'facebook-coloured'
  },
  {
    name: 'discord',
    iconName: 'discord-coloured'
  },
  {
    name: 'telegram',
    iconName: 'telegram-coloured'
  },
  {
    name: 'linkedin',
    iconName: 'linkedin-coloured'
  },
  {
    name: 'slack',
    iconName: 'slack-coloured'
  }
];

export const LOGIN_TYPE = {
  OTP: 'OTP',
  PASSWORD: 'PASSWORD'
};
