import style from './FloatingLabelInput.module.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import EyeVisible from '../../Icons/EyeVisible';

const className = 'c-FloatingLabelInput';

// TODO: Add Icons and hover states
// https://www.figma.com/file/uypc3B2NlqSGqbcB7M4oG8/Nas.io-Onboarding-Flow?node-id=301%3A27166

const FloatingLabelInput = (props) => {
  const {
    type = 'text',
    name,
    label,
    value,
    placeholder,
    onChange,
    customInputClasses,
    customLabelClasses,
    disabled,
    sideLabel,
    error,
    focus = false,
    dataTestId,
    ...rest
  } = props;
  const ref = useRef();
  const [isEyeIconVisible, setIsEyeIconVisible] = useState(false);

  const handleLabelOnClick = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [focus]);

  const toggleInputVisibility = () => {
    const inputType = ref?.current?.type;
    if (inputType === 'password') ref.current.type = 'text';
    else ref.current.type = 'password';

    setIsEyeIconVisible((prevBool) => !prevBool);
  };

  const renderEyeIcon = () => {
    return (
      // eslint-disable-next-line jsx-a11y/interactive-supports-focus
      <div
        role="button"
        className={'absolute right-16 top-16 z-20 h-fit w-fit'}
        onClick={toggleInputVisibility}>
        <EyeVisible isOpen={isEyeIconVisible} />
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames(
          `${className} ${style[className]} relative`,
          {
            'max-h-56': !error
          },
          {
            'mt-12': error
          }
        )}>
        <input
          ref={ref}
          type={type}
          name={name}
          value={value}
          data-test-id={dataTestId ? dataTestId : `${name}-input`}
          placeholder={placeholder}
          onChange={onChange}
          className={`z-1 h-full w-full border-1 border-npl-neutral-light-solid-7 p-8 px-16 pt-24 text-label-lg text-npl-text-icon-on-light-surface-primary ${
            error && '!border-error'
          } rounded-12 ${customInputClasses}`}
          disabled={disabled}
          {...rest}
        />
        {
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <label
            htmlFor={name}
            onClick={handleLabelOnClick}
            tabIndex={-1}
            className={`block px-8 text-label-sm font-medium text-npl-text-icon-on-light-surface-secondary ${customLabelClasses}`}>
            {label}
          </label>
        }
        {type === 'password' && renderEyeIcon()}
        {sideLabel && (
          <div className={'absolute right-16 top-18 z-20 h-fit w-fit'}>
            {sideLabel()}
          </div>
        )}
      </div>
      {error && (
        <span className="-bottom-12 text-label-md font-medium text-error">
          {error}
        </span>
      )}
    </>
  );
};

FloatingLabelInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  customInputClasses: PropTypes.string,
  customLabelClasses: PropTypes.string,
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
  dataTestId: PropTypes.string
};

export default FloatingLabelInput;
