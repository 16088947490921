import { t } from '@/utility/localization';
import RoundedButton from '@/components/common/RoundedButton';
import PasswordCreationField from '@/components/common/Form/PasswordCreationField';
import { useState } from 'react';
import { useAuthContext } from '@/contexts/AuthContext';
import { trackGAEvent } from '@/utility/analytics';

const PasswordCreationForm = ({ formValues, postLogin }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordError, setPasswordError] = useState();
  const [password, setPassword] = useState('');
  const { updatePassword } = useAuthContext();

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);

      const updatePasswordCallback = (response) => {
        if (response.error) {
          setIsSubmitting(false);
          setPasswordError(response.error);
          return;
        }

        postLogin?.(response.data.user);

        trackGAEvent('login_with_create_password_success');
      };

      updatePassword({
        email: formValues.email,
        password: password,
        passwordToken: formValues.passwordToken,
        callback: updatePasswordCallback
      });
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const onFieldChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="c-PasswordCreationForm">
      <form onSubmit={onSubmit}>
        <div className="my-24">
          <PasswordCreationField
            focus
            onChange={onFieldChange}
            error={passwordError}
          />
        </div>
        <RoundedButton
          displayType="primary"
          type="submit"
          isLoading={isSubmitting}
          disabled={!password}
          data-testid="password-creation-button"
          customClassNames="w-full h-[48px]">
          {t('continue')}
        </RoundedButton>
      </form>
    </div>
  );
};

export default PasswordCreationForm;
